a {
  text-decoration: none;
}

.MuiSvgIcon-root:not(
    .MuiSvgIcon-colorPrimary,
    .MuiSvgIcon-colorSecondary,
    .MuiSvgIcon-colorError,
    .MuiSvgIcon-colorSuccess,
    .MuiSvgIcon-colorInfo,
    .MuiSvgIcon-colorWarning,
    .MuiSvgIcon-colorDisabled,
    [class*='makeStyles-'],
    [class*='jss']
  ) {
  color: #d5d5d5;
}
