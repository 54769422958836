div#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
}

.MuiAutocomplete-popper {
  background: #f5f5f5;
  border-radius: 5px;
}

.App {
  text-align: center;

  a {
    text-decoration: none;
  }

  .MuiCardActions-root {
    justify-content: center;
  }
}
